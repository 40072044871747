<template>
  <!-- 重构 - 结算管理 - 生成结算单（收款） -->
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;" @click="goReturn()">结算管理</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">生成结算单（收款）</a>
        </span>
      </div>
      <div class="framePage-body">
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="140px" class="demo-ruleForm1"><el-button type="primary" class="bgc-bv ckbjmx" size="small" round @click="seeClassEetails()">查看班级明细
          </el-button>
          <el-form-item label="结算信息" class="ts"></el-form-item>
          <el-form-item label="选择合同" prop="contractId" class="tsWidth">
            <el-select size="small" v-model="ruleForm.contractId" placeholder="请选择选择合同" @change="contractChange"><el-option v-for="item in fdNewContractLists" :key="item.contractId" :label="item.contractName" :value="item.contractId"></el-option>
            </el-select>
            <el-button type="text" style="margin-left: 15px;" @click="previewContract">预览合同</el-button>
            <el-button type="text" v-if="fatherData.addEdit=='edit'" :disabled="attachmentList.length===0" style="margin-left: 15px;" @click="checkTheAttachment">查看附件</el-button>
            <p style="color: red;">结算标准:{{ chargeMessage }}</p>
            <p style="color: red;">付费形式: {{ $setDictionary("AGENCY_PAYMENT_METHOD", paymentMethod) }}</p>
            <p style="color: red;">
              结算方式:
              <el-radio-group v-model="settlementMethod" @change="settlementMethodChange">
                <el-radio v-for="item in settlementMethodList" :key="item.value" :label="item.value">{{item.label}}</el-radio>
              </el-radio-group>
            </p>
            <p style="color: red;">收款时间约定: {{ $setDictionary("CONTRACT_COLLECTION_AGREEMENT", collectionTimeAgreement) }}</p>
          </el-form-item>

          <el-form-item label="结算单号" prop="billNo">
            <el-input size="small" v-model="ruleForm.billNo" :disabled="this.fatherData.addEdit == 'edit'" placeholder="请输入结算单号" clearable></el-input>
            <p style="color: red">单号为空时将自动生成结算单号</p>
          </el-form-item>
          <el-form-item label="类型" prop="billNoMark">
            <el-radio-group v-model="ruleForm.billNoMark">
              <el-radio v-for="item in billNoMarkData" :key="item.value" :label="item.value">{{item.label}}</el-radio>
            </el-radio-group>
          </el-form-item>
          <el-form-item label="班级明细" class="btbzWitch">
            <el-table :data="ruleForm.fdNewProjectBOList" :highlight-current-row="true" size="small" :key="tableKey" tooltip-effect="dark" style="width: 100%" max-height="850" stripe class="tb-edit"><el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px"></el-table-column>
              <el-table-column label="班级编码" align="left" show-overflow-tooltip prop="projectCode" min-width="200"></el-table-column>
              <el-table-column label="班级名称" align="left" show-overflow-tooltip prop="projectName" min-width="200"></el-table-column>
              <el-table-column v-if="['1','4'].includes(differentType)" label="国家补贴标准(元)" align="left" show-overflow-tooltip prop="countrySubsidyStandard" min-width="120"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.countrySubsidyStandard" placeholder="请输入" @keyup.native="
                      scope.row.countrySubsidyStandard = zF.oninput2(
                        scope.row.countrySubsidyStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      disabled1 || fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      disabled1 || fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.countrySubsidyStandard }}</span>
                </template>
              </el-table-column>
              <el-table-column label="结算状态" align="left" show-overflow-tooltip prop="settleState" min-width="100"><template slot-scope="scope">
                  <el-select v-model="scope.row.settleState" placeholder="请选择" size="small">
                    <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
                    </el-option>
                  </el-select>
                  <span>{{
                    scope.row.settleState == 1 ? "已结清" : "未结清"
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="!['3','6'].includes(differentType)" label="总课时数" align="left" show-overflow-tooltip prop="totalPeriod" min-width="100" />
              <el-table-column label="是否预付款" align="left" show-overflow-tooltip prop="isAdvanceCharge" min-width="100"><template slot-scope="scope">
                  <el-select v-model="scope.row.isAdvanceCharge" placeholder="请选择" size="small">
                    <el-option label="是" :value="true" />
                    <el-option label="否" :value="false" />
                  </el-select>
                  <span>{{
                    scope.row.isAdvanceCharge===true ? "是":scope.row.isAdvanceCharge === false?"否":'未设置'
                  }}</span>
                </template>
              </el-table-column>
              <el-table-column label="已结算人数" align="left" show-overflow-tooltip prop="settleFinishedNum" min-width="100" />
              <!-- 合计 人数-->
              <template v-if="['5'].includes(differentType)">
                <el-table-column label="领取补贴收费标准(元)" align="left" show-overflow-tooltip min-width="100"><template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.chargeStandard" placeholder="请输入" @keyup.native="
                      scope.row.chargeStandard = zF.oninput2(
                        scope.row.chargeStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                    <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.chargeStandard }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="领取补贴人数" align="left" show-overflow-tooltip min-width="100"><template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.settleNum" placeholder="请输入" @keyup.native="
                      scope.row.settleNum = zF.negativeTurnjust1(
                        scope.row.settleNum
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                    <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.settleNum }}</span>
                  </template>
                </el-table-column>
                
                <el-table-column label="未领取补贴收费标准(元)" align="left" show-overflow-tooltip min-width="100"><template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.notSubsidiesStandard" placeholder="请输入" @keyup.native="
                      scope.row.notSubsidiesStandard = zF.oninput2(
                        scope.row.notSubsidiesStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                    <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.notSubsidiesStandard }}</span>
                  </template>
                </el-table-column>
                <el-table-column label="未领取补贴人数" align="left" show-overflow-tooltip min-width="100"><template slot-scope="scope">
                    <el-input size="small" v-model="scope.row.notSubsidiesNum" placeholder="请输入" @keyup.native="
                      scope.row.notSubsidiesNum = zF.negativeTurnjust1(
                        scope.row.notSubsidiesNum
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                    <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.notSubsidiesNum }}</span>
                  </template>
                </el-table-column>
              </template>
              <!-- 合计 人数 end-->
              <el-table-column :label="percentage ? '收费标准(%)' : '收费标准(元)'" align="left" v-if="['4','6','7','8','9'].includes(differentType)" show-overflow-tooltip prop="chargeStandard" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.chargeStandard" placeholder="请输入" @keyup.native="
                      scope.row.chargeStandard = zF.oninput2(
                        scope.row.chargeStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.chargeStandard }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="percentage1 ? '技术标准(%)' : '技术标准(元)'" align="left" v-if="['1','2','3'].includes(differentType)" show-overflow-tooltip prop="technologyStandard" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.technologyStandard" placeholder="请输入" @keyup.native="
                      scope.row.technologyStandard = zF.oninput2(
                        scope.row.technologyStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.technologyStandard }}</span>
                </template>
              </el-table-column>
              <el-table-column :label="percentage1 ? '课程标准(%)' : '课程标准(元)'" align="left" v-if="['1','2','3'].includes(differentType)" show-overflow-tooltip prop="courseStandard" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.courseStandard" placeholder="请输入" @keyup.native="
                      scope.row.courseStandard = zF.oninput2(
                        scope.row.courseStandard,
                        2
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.courseStandard }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="!['5'].includes(differentType)" label="结算人数" align="left" show-overflow-tooltip prop="canSettleNum" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.settleNum" placeholder="请输入" @keyup.native="
                      scope.row.settleNum = zF.negativeTurnjust1(
                        scope.row.settleNum
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.settleNum }}</span>
                </template>
              </el-table-column>
              <template v-if="!['5'].includes(differentType)">
                  <!--ltf-->
                  <el-table-column label="领取补贴人数" align="left" show-overflow-tooltip min-width="110"><template slot-scope="scope">
                      <el-input size="small" v-model="scope.row.subsidiesNum" placeholder="请输入" @keyup.native="
                        scope.row.subsidiesNum = zF.negativeTurnjust1(
                          scope.row.subsidiesNum
                        )
                      "
                        @blur="updateValue(scope.$index, '1',scope.row.subsidiesNum)"
                        :style="
                        fatherData.addEdit == 'newSettlementSheet'
                          ? 'display: none;'
                          : ''
                      "></el-input>
                      <span :style="
                        fatherData.addEdit == 'newSettlementSheet'
                          ? 'display: block;'
                          : ''
                      ">{{ scope.row.subsidiesNum }}</span>
                  </template>
                  </el-table-column>

                  <el-table-column label="未领取补贴人数" align="left" show-overflow-tooltip min-width="110"><template slot-scope="scope">
                      <el-input size="small" v-model="scope.row.notSubsidiesNum" placeholder="请输入" @keyup.native="
                        scope.row.notSubsidiesNum = zF.negativeTurnjust1(
                          scope.row.notSubsidiesNum
                        )
                      "
                        @blur="updateValue(scope.$index, '2',scope.row.notSubsidiesNum)"
                        :style="
                        fatherData.addEdit == 'newSettlementSheet'
                          ? 'display: none;'
                          : ''
                      "></el-input>
                      <span :style="
                        fatherData.addEdit == 'newSettlementSheet'
                          ? 'display: block;'
                          : ''
                      ">{{ scope.row.notSubsidiesNum }}</span>
                  </template>
                  </el-table-column>
              </template>
              <el-table-column label="二次结算人数" align="left" v-if="fatherData.addEdit == 'newSettlementSheet'" show-overflow-tooltip prop="secondPerson" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.secondPerson" placeholder="请输入" @keyup.native="
                      scope.row.secondPerson = zF.negativeTurnjust1(
                        scope.row.secondPerson
                      )
                    "></el-input>
                  <span>{{ scope.row.secondPerson }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="['3','6'].includes(differentType)" label="总课时" align="left" show-overflow-tooltip prop="totalPeriod" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.totalPeriod" placeholder="请输入" @keyup.native="
                      scope.row.totalPeriod = zF.negativeTurnjust1(
                        scope.row.totalPeriod
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.totalPeriod }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="['3','6'].includes(differentType)" label="公共课时" align="left" show-overflow-tooltip prop="publicLessonNum" min-width="100"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.publicLessonNum" placeholder="请输入" @keyup.native="
                      scope.row.publicLessonNum = zF.negativeTurnjust1(
                        scope.row.publicLessonNum
                      )
                    " @blur="algorithm(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.publicLessonNum }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="['1','2','3'].includes(differentType)" label="平台技术服务费（元）" align="left" show-overflow-tooltip prop="platformTechnicalFee" min-width="150"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.platformTechnicalFee" placeholder="请输入" @keyup.native="
                      scope.row.platformTechnicalFee = zF.oninput2(
                        scope.row.platformTechnicalFee,
                        2
                      )
                    " @input="platformCoursesCost(scope.$index, 'list')" :style="
                      disabled2 || fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      disabled2 || fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.platformTechnicalFee }}</span></template>
              </el-table-column>
              <el-table-column v-if="['1','2','3'].includes(differentType)" label="课程服务费（元）" align="left" show-overflow-tooltip prop="courseFee" min-width="120"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.courseFee" placeholder="请输入" @keyup.native="
                      scope.row.courseFee = zF.oninput2(scope.row.courseFee, 2)
                    " @input="platformCoursesCost(scope.$index, 'list')" :style="
                      disabled2 || fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      disabled2 || fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.courseFee }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="['4','5','6','7','8','9'].includes(differentType)" label="平台服务费（元）" align="left" show-overflow-tooltip prop="platformTotalFee" min-width="120"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.platformTotalFee" placeholder="请输入" @keyup.native="
                      scope.row.platformTotalFee = zF.oninput2(
                        scope.row.platformTotalFee,
                        2
                      )
                    " @input="platformCoursesCost(scope.$index, 'list')" :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: none;'
                        : ''
                    "></el-input>
                  <span :style="
                      fatherData.addEdit == 'newSettlementSheet'
                        ? 'display: block;'
                        : ''
                    ">{{ scope.row.platformTotalFee }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="fatherData.addEdit != 'newSettlementSheet'" label="平台结算金额（元）" align="left" show-overflow-tooltip prop="platformSettlementAmount" min-width="140"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.platformSettlementAmount" placeholder="请输入" @keyup.native="
                      scope.row.platformSettlementAmount = zF.oninput2(
                        scope.row.platformSettlementAmount,
                        2
                      )
                    "></el-input>
                  <span>{{ scope.row.platformSettlementAmount }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="fatherData.addEdit == 'newSettlementSheet'" label="平台结算金额（元）" align="left" show-overflow-tooltip prop="secondFee" min-width="140"><template slot-scope="scope">
                  <el-input size="small" v-model="scope.row.secondFee" placeholder="请输入" @keyup.native="
                      scope.row.secondFee = zF.oninput2(scope.row.secondFee, 2)
                    "></el-input>
                  <span>{{ scope.row.secondFee }}</span>
                </template>
              </el-table-column>
              <el-table-column label="培训类型" align="left" show-overflow-tooltip prop="categoryName" min-width="140" />
              <el-table-column label="培训方式" align="center" show-overflow-tooltip prop="trainMethod" min-width="160px"><template slot-scope="scope">
                  <span>{{
                      $setDictionary(
                          "PROJECT_TRAIN_METHOD",
                          scope.row.trainMethod
                      )
                    }}</span>
                </template>
              </el-table-column>
              <el-table-column v-if="fatherData.addEdit == 'newSettlementSheet'" label="操作" align="left" show-overflow-tooltip prop="platformSettlementAmount" min-width="140"><template slot-scope="scope">
                  <el-button type="text" size="mini" style="padding: 0 5px" @click="deleteList(scope.row, scope.$index)">删除
                  </el-button>
                </template>
              </el-table-column>
            </el-table>
          </el-form-item>
          <el-form-item label="收费标准" prop="chargesSettlement" class="sfbzWidth">
            <el-input type="textarea" maxlength="1000" resize="none" placeholder="请输入收费标准" show-word-limit v-model="ruleForm.chargesSettlement"></el-input>
          </el-form-item>
          <el-form-item label="备注" class="sfbzWidth">
            <el-input type="textarea" maxlength="500" placeholder="请输入备注" resize="none" show-word-limit v-model="ruleForm.remark"></el-input>
          </el-form-item>
          <el-form-item label="收付款账号信息" class="ts"></el-form-item>
          <el-form-item label="付款单位" prop="payerName">
            <el-input v-model="ruleForm.payerName" placeholder="请输入付款单位" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="代付款单位" prop="replacePayerName">
            <el-input v-model="ruleForm.replacePayerName" placeholder="请输入代付款单位" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="收款单位" prop="payeeName">
            <el-input v-model="ruleForm.payeeName" placeholder="请输入收款单位" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="开户银行" prop="payeeBank">
            <el-input v-model="ruleForm.payeeBank" placeholder="请输入开户银行" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item label="银行账号" prop="payeeAccount">
            <el-input v-model="ruleForm.payeeAccount" placeholder="请输入银行账号" size="small" maxlength="50"></el-input>
          </el-form-item>
          <el-form-item class="operation">
            <el-button type="primary" class="bgc-bv" size="small" round @click="goReturn()">返回</el-button>
            <el-button type="primary" class="bgc-bv" size="small" round @click="generatePreview('ruleForm', 1)">预览结算单</el-button>
            <el-button v-if="fatherData.addEdit == 'newSettlementSheet'" type="primary" class="bgc-bv" size="small" round @click="generatePreview('ruleForm', 4)">生成结算单</el-button>
            <el-button v-else type="primary" class="bgc-bv" size="small" round @click="generatePreview('ruleForm', 2)">生成结算单</el-button>
            <el-button v-if="fatherData.addEdit != 'newSettlementSheet'" type="primary" class="bgc-bv" size="small" round @click="generatePreview('ruleForm', 3)">生成结算单并提交领导审核</el-button>
            <el-button v-if="fatherData.billApplySource != '0'" type="primary" class="bgc-bv" size="small" round @click="reject('ruleForm', 3)">驳回</el-button>
          </el-form-item>
        </el-form>
      </div>
    </div>
    <!-- 查看班级明细 - loading -->
    <el-dialog title="查看班级明细" :visible.sync="dialogVisible1" width="50%" top="2%" center>
      <div id="pdf-cert1" style="height: 600px"></div>
    </el-dialog>
    <!-- 预览合同 - loading -->
    <el-dialog title="预览合同" :visible.sync="dialogVisible3" width="900px" top="2%" center>
      <contractManagementAdd :seeTH="true" :seeContractId="ruleForm.contractId" :isApprovalTh="ruleForm.isApproval" v-if="dialogVisible3" />
    </el-dialog>
    <!-- 预览结算单 - loading -->
    <el-dialog title="预览" :visible.sync="dialogVisible2" width="50%" top="2%" center>
      <div id="pdf-cert2" style="height: 600px"></div>
    </el-dialog>
    <!-- 驳回 - loading -->
    <el-dialog title="驳回" :visible.sync="rejectDialog" width="30%" class="toVoidLoding">
      <div style="margin-bottom: 15px">
        <el-input type="textarea" placeholder="请输入驳回原因" v-model="remark" maxlength="255" show-word-limit>
        </el-input>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" class="bgc-bv" round @click="toVoidDetermineClose">取 消</el-button>
        <el-button type="primary" size="small" class="bgc-bv" round @click="toVoidDetermine">确 定</el-button>
      </span>
    </el-dialog>
    <!-- 查看附件列表 - loading -->
    <el-dialog title="查看附件" :visible.sync="previewLoding" width="50%" center class="preview-857" :close-on-click-modal="false" v-dialogDrag>
      <el-table :data="attachmentList" tooltip-effect="dark" style="width: 100%;line-height: 40px;" stripe>
        <el-table-column label="序号" type="index"></el-table-column>
        <el-table-column prop="fileName" label="文件名" />
        <el-table-column prop="fullName" label="上传人" />
        <el-table-column prop="fileType" label="文件格式" />
        <el-table-column label="操作" width="80">
          <template slot-scope="{ row }">
            <el-button type="text" size="small" @click="lookPdf(row)">查看</el-button>
            <el-image style="width: 0; height: 0" :ref="'img'+row.billFileId" :src="row.fileUrl" :preview-src-list="[row.fileUrl]">
            </el-image>
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>
    <!-- 查看附件详情 - loading -->
    <el-dialog title="预览" :visible.sync="previewLoding1" v-if="previewLoding1" width="50%" :close-on-click-modal="false" top="2%" center v-dialogDrag append-to-body>
      <div style="height: 600px;">
        <div id="pdf-cert3" style="height: 600px" v-if="fileType == 'pdf' || fileType == 'PDF'"></div>
      </div>
    </el-dialog>
  </div>
</template>
<script>
import pdf from "pdfobject";
import contractManagementAdd from "@/views/financialSettlement/contractManagementAdd";
import { console } from "../../../utils/helper";
export default {
  name: "singleAdministrationChildrenFirstEdit",
  components: {
    contractManagementAdd,
  },
  data () {
    return {
      // 父页面带过的参数
      fatherData: {
        id: "", // 待结算列表无id;结算单管理有id
        type: "", // first-收款结算单;2-付款结算单
        addEdit: "", // add-待结算列表;edit-结算单管理;newSettlementSheet-添加新结算单
        refresh: false, // 返回列表页是否刷新页面
      },
      // 页面数据
      ruleForm: {
        billId: "", // 结算单id
        billNo: "", // 结算单编号
        billNoMark: "", // 类型
        contractId: "", // 合同
        trainTypeId: "", // 培训类型id
        fdNewProjectBOList: [], // 国家补贴标准列表数据
        chargesSettlement: "", // 收费标准
        remark: "", // 备注
        payerName: "", // 付款单位
        replacePayerName: "", // 代付款单位
        payeeName: "北京晟融信息技术有限公司", // 收款单位
        payeeBank: "中国民生银行股份有限公司北京苏州街支行", // 开户银行
        payeeAccount: "154322354", // 银行账号
        settlementUserCount: 0, // 潜在字段 - 结算人数
        totalLessonNum: 0, // 潜在字段 - 总课时
        openLessonNum: 0, // 潜在字段 - 总公共课时
        payerAmount: 0, // 潜在字段 - 平台服务费（元）
        settlementAmount: 0, // 潜在字段 - 平台结算金额（元）
      },
      // 合同下拉数据
      fdNewContractLists: [],
      // 类型单选数据
      billNoMarkData: [],
      // 数据校验
      rules: {
        // 选择合同
        contractId: [
          { required: true, message: "请选择合同", trigger: "change" },
        ],
        // 类型
        billNoMark: [
          { required: true, message: "请选择类型", trigger: "change" },
        ],
        // 收费标准
        chargesSettlement: [
          { required: true, message: "请输入收费标准", trigger: "blur" },
        ],
        // 付款单位
        payerName: [
          { required: true, message: "请输入付款单位", trigger: "blur" },
        ],
        // 收款单位
        payeeName: [
          { required: true, message: "请输入收款单位", trigger: "blur" },
        ],
        // 开户银行
        payeeBank: [
          { required: true, message: "请输入开户银行", trigger: "blur" },
        ],
        // 银行账号
        payeeAccount: [
          { required: true, message: "请输入银行账号", trigger: "blur" },
        ],
      },
      // 国家补贴标准列表 - 国家补贴标准（元）是否可以输入
      disabled1: false,
      // 国家补贴标准列表 - 平台技术服务费（元）/ 课程服务费（元）是否可以输入
      disabled2: false,
      // 国家补贴标准列表 - 平台服务费（元）是否可以输入
      disabled3: false,
      // 查看班级明细 - loading
      dialogVisible1: false,
      // 预览结算单 - loading
      dialogVisible2: false,
      // 预览合同 - loading
      dialogVisible3: false,
      // 列表里 - 结算状态
      options: [
        {
          value: 1,
          label: "已结清",
        },
        {
          value: 0,
          label: "未结清",
        },
      ],
      // 列表里 - 收费标准版列 - 的显示与隐藏
      show: false,
      // 列表里 - 课程标准/技术服务费 - 的显示与隐藏
      show1: false,
      // 列表里 - 收费标准%-元 - 的显示与隐藏
      percentage: false,
      // 列表里 - 课程标准/技术服务费 %-元 - 的显示与隐藏
      percentage1: false,
      chargeMessage: "", //合同结算标准信息
      rejectDialog: false, //驳回弹窗
      remark: "", //驳回原因
      paymentMethod: '', //付费形式
      settlementMethod: '', //结算方式
      settlementMethodList: [], //结算方式list
      isFirstFlag: true,
      attachmentList: [],//附件集合
      previewLoding: false,//查看附件列表弹窗
      previewLoding1: false,//查看附件详情弹窗
      ImgSrc: "",
      fileType: "",
      tableKey: Math.random(),
    };
  },
  created () {
    this.fatherData.id = JSON.parse(this.$route.query.editInfo).id;
    this.fatherData.type = JSON.parse(this.$route.query.editInfo).type;
    this.fatherData.addEdit = JSON.parse(this.$route.query.editInfo).addEdit;
    this.fatherData.billApplySource = JSON.parse(this.$route.query.editInfo).billApplySource;
    this.fatherData.auditType = JSON.parse(this.$route.query.editInfo).auditType;
    this.getInvoice_type();
    this.generateSettlementDocument();
    this.getcontractSettlementMethod_type();
  },
  mounted () { },
  computed: {
    // 收款时间约定
    collectionTimeAgreement () {
      if (!this.ruleForm.contractId) return ''
      const obj = this.fdNewContractLists.find(e => e.contractId == this.ruleForm.contractId)
      return obj?.collectionTimeAgreement ?? ''
    },
    // 表格结算类型，用于显示不同的列表项
    differentType () {
      if (!this.ruleForm.contractId) return ''
      const obj = this.fdNewContractLists.find(e => e.contractId == this.ruleForm.contractId)
      const settleContent = JSON.parse(obj.settleContent)
      const type = new Map([//自定义结算类型
        ['1', ['10', '10']],//分开，比例
        ['2', ['10', '20']],//分开，人数
        ['3', ['10', '30']],//分开，课时
        ['4', ['20', '10']],//合计，比例
        ['5', ['20', '20']],//合计，人数
        ['6', ['20', '30']],//合计，课时
        ['7', ['20', '40']],//合计，次
        ['8', ['30']],//固定
        ['9', ['40']],//其他
      ])
      for (let [k, v] of type) {
        if (obj.settleStandardType == '10' || obj.settleStandardType == '20') {
          if (obj.settleStandardType == v[0] && settleContent.charge == v[1]) {
            return k
          }
        } else {
          if (obj.settleStandardType == v[0]) {
            return k
          }
        }
      }
    }
  },
  watch: {
    // 监听合同 - 变更：收费标准/付款单位
    "ruleForm.contractId": {
      handler: function (newVal) {
        // 赋值该选中合同参数
        let lData = [];
        // 循环合同
        for (let i = 0; i < this.fdNewContractLists.length; i++) {
          // 判断当前合同;取该合同里的参数
          if (this.fdNewContractLists[i].contractId == newVal) {
            this.ruleForm.chargesSettlement = this.fdNewContractLists[i].chargesSettlement;
            // 如果是修改状态，则不会自动回显
            if (this.fatherData.addEdit == "add") {
              this.ruleForm.payerName = this.fdNewContractLists[i].remark;
            }
            // 赋值给变量
            lData = this.fdNewContractLists[i];
          }
        }
        // 解参数;把合同中的（lData.settleContent）string变成object
        let htData = JSON.parse(lData.settleContent);
        // 判断国家补贴标准-add:算初始值;否则不参与计算
        if (this.fatherData.addEdit == "add") {
          for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
            // console.log(this.settlementMethod,this.ruleForm.fdNewProjectBOList[i])
            this.settleNumSource(this.ruleForm.fdNewProjectBOList[i], i)
            if (Object.values(htData).length > 0) {
              // settleStandardType = 10;技术/课程费用分开
              if (lData.settleStandardType == "10") {
                this.show = false;
                this.show1 = true;
                this.ruleForm.fdNewProjectBOList[i].platformTotalFee = 0;
                // charge = 10;按比例收费
                if (htData.charge == "10") {
                  this.disabled1 = false;
                  this.disabled2 = false;
                  this.disabled3 = true;
                  this.percentage1 = true;
                  this.chargeMessage =
                    "技术/课程费用分开,按比例收费,技术比例" +
                    htData.technologyRatio +
                    "%,课程比例" +
                    htData.coursesRatio +
                    "%";
                  // 技术标准
                  this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                    htData.technologyRatio;
                  // 课程标准
                  this.ruleForm.fdNewProjectBOList[i].courseStandard =
                    htData.coursesRatio;
                  // 结算方式settlementMethod 10 按注册人数   20 按入班人数   30 按学习人数   40 按完成人数   50 按结业人数

                  // 判断 - 国家补贴标准(元) && 结算人数
                  if (
                    this.ruleForm.fdNewProjectBOList[i]
                      .countrySubsidyStandard &&
                    this.ruleForm.fdNewProjectBOList[i].settleNum
                  ) {
                    // 平台技术服务费（元）= 技术标准 * 国家补贴标准 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = (
                      (Number(
                        this.ruleForm.fdNewProjectBOList[i].technologyStandard || 0
                      ) /
                        100) *
                      Number(
                        this.ruleForm.fdNewProjectBOList[i]
                          .countrySubsidyStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 课程服务费（元）= 课程标准 * 国家补贴标准 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].courseFee = (
                      (Number(
                        this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                      ) /
                        100) *
                      Number(
                        this.ruleForm.fdNewProjectBOList[i]
                          .countrySubsidyStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                      ) +
                      Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                      )
                    ).toFixed(2);
                    // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].settlementAmount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                      ) +
                      Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                      )
                    ).toFixed(2);
                    // 判断 - 公共课是否收费
                    if (htData.publicCourses == "否") {
                      // 课程服务费（元）= 课程服务费（元）- (公共课时/总课时) * 课程服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].courseFee = (
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) -
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].publicLessonNum || 0
                        ) /
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0
                          )) *
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                    }
                  } else {
                    // 平台技术服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee =
                      "";
                    // 课程服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                }
                // charge = 20;按人数收费
                if (htData.charge == "20") {
                  this.disabled1 = true;
                  this.disabled2 = false;
                  this.disabled3 = true;
                  this.percentage1 = false;
                  this.chargeMessage =
                    "技术/课程费用分开,按人数收费,技术每人收费" +
                    htData.technologyCharge +
                    "元,课程每人收" +
                    htData.coursesCharge +
                    "元";
                  // 技术标准
                  this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                    htData.technologyCharge;
                  // 课程标准
                  this.ruleForm.fdNewProjectBOList[i].courseStandard =
                    htData.coursesCharge;
                  // 判断 - 如果有结算人数;数据参与计算,否则不参与计算
                  if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                    // 平台技术服务费（元）= 技术每人收费 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].technologyStandard || 0
                      ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 课程服务费（元）= 课程每人收费 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].courseFee = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                      ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                      ) +
                      Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                      )
                    ).toFixed(2);
                    // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                      ) +
                      Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                      )
                    ).toFixed(2);
                    // 判断 - 公共课是否收费
                    if (htData.publicCourses == "否") {
                      // 课程服务费（元）= 课程服务费（元）- (公共课时 / 总课时) * 课程服务费
                      this.ruleForm.fdNewProjectBOList[i].courseFee = (
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) -
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].publicLessonNum || 0
                        ) /
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0
                          )) *
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                    }
                  } else {
                    // 平台技术服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee =
                      "";
                    // 课程服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                }
                // charge = 30;按课时收费
                if (htData.charge == "30") {
                  this.disabled1 = true;
                  this.disabled2 = false;
                  this.disabled3 = true;
                  this.percentage1 = false;
                  this.chargeMessage =
                    "技术/课程费用分开,按课时收费,技术每人/课时收费" +
                    htData.technologyChargePerson +
                    "元,课程每人/课时收费" +
                    htData.coursesChargePerson +
                    "元";
                  // 技术标准
                  this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                    htData.technologyChargePerson;
                  // 课程标准
                  this.ruleForm.fdNewProjectBOList[i].courseStandard =
                    htData.coursesChargePerson;
                  // 判断 - 如果有结算人数;数据参与计算,否则不参与计算
                  if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                    // 平台技术服务费（元）= 技术每人/课时收费 * 结算人数 * 总课时
                    this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].technologyStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0)
                    ).toFixed(2);
                    // 课程服务费（元）= 课程每人/课时收费 * 结算人数 * 总课时
                    this.ruleForm.fdNewProjectBOList[i].courseFee = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0)
                    ).toFixed(2);
                    // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                      ) +
                      Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                      )
                    ).toFixed(2);
                    // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                      ) +
                      Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                      )
                    ).toFixed(2);
                    // 判断 - 公共课是否收费
                    if (htData.publicCourses == "否") {
                      // 课程服务费（元）= (总课时 - 公共课时) * 课程每人/课时收费 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].courseFee = (
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0
                        ) -
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].publicLessonNum || 0
                          )) *
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                    }
                  } else {
                    // 平台技术服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee =
                      "";
                    // 课程服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                }
              }
              // settleStandardType = 20;技术/课程费用合计
              if (lData.settleStandardType == "20") {
                this.show = true;
                this.show1 = false;
                this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = 0;
                this.ruleForm.fdNewProjectBOList[i].courseFee = 0;
                // charge = 10;按比例收费
                if (htData.charge == "10") {
                  this.disabled1 = false;
                  this.disabled2 = true;
                  this.disabled3 = false;
                  this.percentage = true;
                  this.chargeMessage =
                    "技术/课程费用合计,按比例收费,比例" + htData.ratio + "%";
                  // 收费标准
                  this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    htData.ratio;
                  // 判断 - 国家补贴标准(元) && 结算人数;数据参与计算,否则不参与计算
                  if (
                    this.ruleForm.fdNewProjectBOList[i]
                      .countrySubsidyStandard &&
                    this.ruleForm.fdNewProjectBOList[i].settleNum
                  ) {
                    // 平台服务费（元）= 按比例收费/100 * 国家补贴标准(元) * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                      (Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                      ) /
                        100) *
                      Number(
                        this.ruleForm.fdNewProjectBOList[i]
                          .countrySubsidyStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 列表不显示 - 总费用 = 按比例收费/100 * 国家补贴标准(元) * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      (Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                      ) /
                        100) *
                      Number(
                        this.ruleForm.fdNewProjectBOList[i]
                          .countrySubsidyStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 列表不显示 -平台结算金额（元）= 按比例收费/100 * 国家补贴标准(元) * 结算人数
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = (
                      (Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                      ) /
                        100) *
                      Number(
                        this.ruleForm.fdNewProjectBOList[i]
                          .countrySubsidyStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                  } else {
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                }
                // charge = 20;按人数收费
                if (htData.charge == "20") {
                  this.disabled1 = true;
                  this.disabled2 = true;
                  this.disabled3 = false;
                  this.percentage = false;
                  this.chargeMessage =
                    "技术/课程费用合计,领取补贴每人收费" +
                    htData.chargePerson +
                    "元,未领取补贴每人" +
                    (htData?.chargeUnPerson ?? ' ') +
                    "元";
                  // 收费标准
                  this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    htData.chargePerson;
                  // 判断 - 结算人数;数据参与计算,否则不参与计算
                  if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                    // 平台服务费（元）= 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                      (Number(this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)) +
                      (Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard || 0))
                    ).toFixed(2);
                    //  列表不显示 - 总费用 = 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      (Number(this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)) +
                      (Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard || 0))
                    ).toFixed(2);
                    // 列表不显示 - 平台结算金额（元） = 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = (
                      (Number(this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)) +
                      (Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard || 0))
                    ).toFixed(2);
                  } else {
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                  this.$set(this.ruleForm.fdNewProjectBOList[i],'notSubsidiesNum',0)
                  // debugger
                  this.$set(this.ruleForm.fdNewProjectBOList[i],'notSubsidiesStandard',(htData?.chargeUnPerson ?? ''))
                }
                // charge = 30;按课时收费
                if (htData.charge == "30") {
                  this.disabled1 = true;
                  this.disabled2 = true;
                  this.disabled3 = false;
                  this.chargeMessage =
                    "技术/课程费用合计,按课时每人收费" +
                    htData.chargeHourPerson +
                    "元";
                  // 收费标准
                  this.ruleForm.fdNewProjectBOList[i].chargeStandard = htData.chargeHourPerson;
                  // 判断 - 总课时 && 结算人数;数据参与计算,否则不参与计算
                  if (
                    this.ruleForm.fdNewProjectBOList[i].totalPeriod &&
                    this.ruleForm.fdNewProjectBOList[i].settleNum
                  ) {
                    // 平台服务费（元）= 每人/课时收费 * 总课时 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 列表不显示 - 总费用 = 每人/课时收费 * 总课时 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                    // 列表不显示 -平台结算金额（元）= 每人/课时收费 * 总课时 * 结算人数
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                      ) *
                      Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0) *
                      Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                    ).toFixed(2);
                  } else {
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                }
                // charge = 40;按次数结算
                if (htData.charge == "40") {
                  this.disabled1 = true;
                  this.disabled2 = true;
                  this.disabled3 = false;
                  let message = "";
                  for (var n = 0; n < htData.SettlementByTimeList.length; n++) {
                    message +=
                      "第" +
                      (n + 1) +
                      "次" +
                      htData.SettlementByTimeList[n].SettlementByTime +
                      "元/人;";
                    if (
                      n == this.ruleForm.fdNewProjectBOList[i].projectSettleNum
                    ) {
                      this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                        htData.SettlementByTimeList[n].SettlementByTime;
                    }
                    // if (
                    //   this.ruleForm.fdNewProjectBOList[i].projectSettleNum >= htData.SettlementByTimeList.length
                    // ) {
                    //   console.log(n)
                    //   // 收费标准
                    //   this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    //     htData.SettlementByTimeList[0].SettlementByTime;
                    // } else {
                    //    if (
                    //  n +=
                    //   this.ruleForm.fdNewProjectBOList[i].projectSettleNum && n<htData.SettlementByTimeList.length
                    // ) {
                    //   console.log(n)
                    //   // 收费标准
                    //   this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    //     htData.SettlementByTimeList[n].SettlementByTime ||''
                    // }
                    // }
                  }
                  this.chargeMessage = "按次数结算:" + message;
                  // 收费标准
                  // this.ruleForm.fdNewProjectBOList[i].chargeStandard = htData.chargeHourPerson;
                  // 判断 - 总课时 && 结算人数;数据参与计算,否则不参与计算
                  if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                    // 平台服务费（元）= 次数收费 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard
                      ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                    ).toFixed(2);
                    // 列表不显示 - 总费用 = 每人/课时收费 * 总课时 * 结算人数
                    this.ruleForm.fdNewProjectBOList[i].paycount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard
                      ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                    ).toFixed(2);
                    // 列表不显示 -平台结算金额（元）= 次数收费 * 结算人数
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = (
                      Number(
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard
                      ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                    ).toFixed(2);
                  } else {
                    // 平台服务费（元）
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                    // 列表不显示 - 总费用
                    this.ruleForm.fdNewProjectBOList[i].paycount = "";
                    // 列表不显示 -平台结算金额（元）
                    this.ruleForm.fdNewProjectBOList[
                      i
                    ].platformSettlementAmount = "";
                  }
                }
              }
              // settleStandardType = 30;固定费用
              if (lData.settleStandardType == "30") {
                this.disabled1 = false;
                this.disabled2 = false;
                this.disabled3 = false;
                // this.show = false;
                // this.show1 = false;
                this.chargeMessage = "固定费用" + htData.fixedCost + "元/年";
                // 平台服务费（元）= 固定费用
                this.ruleForm.fdNewProjectBOList[i].platformTotalFee =
                  htData.fixedCost;
                // 列表不显示 - 总费用 = 固定费用
                this.ruleForm.fdNewProjectBOList[i].paycount = htData.fixedCost;
                // 列表不显示 - 平台结算金额（元）= 固定费用
                this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                  htData.fixedCost;
              }
              // settleStandardType = 40;其他
              if (lData.settleStandardType == "40") {
                this.chargeMessage = "其他";
              }
            } else {
              // 平台服务费（元）
              this.$message({
                message:
                  "该“" +
                  lData.contractName +
                  "”的合同结算标准有误，请联系管理员!",
                type: "warning",
              });
              break;
            }
            this.isAdvanceChargeHandle(lData.collectionTimeAgreement, this.ruleForm.fdNewProjectBOList[i])
          }
        } else {
          for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
            if (!this.isFirstFlag) {
              this.settleNumSource(this.ruleForm.fdNewProjectBOList[i], i)
              if (Object.values(htData).length > 0) {
                // settleStandardType = 10;技术/课程费用分开
                // settleStandardType = 10;技术/课程费用分开
                if (lData.settleStandardType == "10") {
                  this.show = false;
                  this.show1 = true;
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = 0;
                  // charge = 10;按比例收费
                  if (htData.charge == "10") {
                    this.disabled1 = false;
                    this.disabled2 = false;
                    this.disabled3 = true;
                    this.percentage1 = true;
                    this.chargeMessage =
                      "技术/课程费用分开,按比例收费,技术比例" +
                      htData.technologyRatio +
                      "%,课程比例" +
                      htData.coursesRatio +
                      "%";
                    // 技术标准
                    this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                      htData.technologyRatio;
                    // 课程标准
                    this.ruleForm.fdNewProjectBOList[i].courseStandard =
                      htData.coursesRatio;
                    // 结算方式settlementMethod 10 按注册人数   20 按入班人数   30 按学习人数   40 按完成人数   50 按结业人数

                    // 判断 - 国家补贴标准(元) && 结算人数
                    if (
                      this.ruleForm.fdNewProjectBOList[i]
                        .countrySubsidyStandard &&
                      this.ruleForm.fdNewProjectBOList[i].settleNum
                    ) {
                      // 平台技术服务费（元）= 技术标准 * 国家补贴标准 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = (
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].technologyStandard || 0
                        ) /
                          100) *
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .countrySubsidyStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 课程服务费（元）= 课程标准 * 国家补贴标准 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].courseFee = (
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                        ) /
                          100) *
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .countrySubsidyStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].settlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 判断 - 公共课是否收费
                      if (htData.publicCourses == "否") {
                        // 课程服务费（元）= 课程服务费（元）- (公共课时/总课时) * 课程服务费（元）
                        this.ruleForm.fdNewProjectBOList[i].courseFee = (
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) -
                          (Number(
                            this.ruleForm.fdNewProjectBOList[i].publicLessonNum || 0
                          ) /
                            Number(
                              this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0
                            )) *
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0)
                        ).toFixed(2);
                        // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                        this.ruleForm.fdNewProjectBOList[i].paycount = (
                          Number(
                            this.ruleForm.fdNewProjectBOList[i]
                              .platformTechnicalFee || 0
                          ) +
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                          )
                        ).toFixed(2);
                        // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                        this.ruleForm.fdNewProjectBOList[
                          i
                        ].platformSettlementAmount = (
                          Number(
                            this.ruleForm.fdNewProjectBOList[i]
                              .platformTechnicalFee || 0
                          ) +
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                          )
                        ).toFixed(2);
                      }
                    } else {
                      // 平台技术服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee =
                        "";
                      // 课程服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                  }
                  // charge = 20;按人数收费
                  if (htData.charge == "20") {
                    this.disabled1 = true;
                    this.disabled2 = false;
                    this.disabled3 = true;
                    this.percentage1 = false;
                    this.chargeMessage =
                      "技术/课程费用分开,按人数收费,技术每人收费" +
                      htData.technologyCharge +
                      "元,课程每人收" +
                      htData.coursesCharge +
                      "元";
                    // 技术标准
                    this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                      htData.technologyCharge;
                    // 课程标准
                    this.ruleForm.fdNewProjectBOList[i].courseStandard =
                      htData.coursesCharge;
                    // 判断 - 如果有结算人数;数据参与计算,否则不参与计算
                    if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                      // 平台技术服务费（元）= 技术每人收费 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].technologyStandard || 0
                        ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 课程服务费（元）= 课程每人收费 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].courseFee = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                        ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 判断 - 公共课是否收费
                      if (htData.publicCourses == "否") {
                        // 课程服务费（元）= 课程服务费（元）- (公共课时 / 总课时) * 课程服务费
                        this.ruleForm.fdNewProjectBOList[i].courseFee = (
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) -
                          (Number(
                            this.ruleForm.fdNewProjectBOList[i].publicLessonNum || 0
                          ) /
                            Number(
                              this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0
                            )) *
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0)
                        ).toFixed(2);
                        // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                        this.ruleForm.fdNewProjectBOList[i].paycount = (
                          Number(
                            this.ruleForm.fdNewProjectBOList[i]
                              .platformTechnicalFee || 0
                          ) +
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                          )
                        ).toFixed(2);
                        // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                        this.ruleForm.fdNewProjectBOList[
                          i
                        ].platformSettlementAmount = (
                          Number(
                            this.ruleForm.fdNewProjectBOList[i]
                              .platformTechnicalFee || 0
                          ) +
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                          )
                        ).toFixed(2);
                      }
                    } else {
                      // 平台技术服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee =
                        "";
                      // 课程服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                  }
                  // charge = 30;按课时收费
                  if (htData.charge == "30") {
                    this.disabled1 = true;
                    this.disabled2 = false;
                    this.disabled3 = true;
                    this.percentage1 = false;
                    this.chargeMessage =
                      "技术/课程费用分开,按课时收费,技术每人/课时收费" +
                      htData.technologyChargePerson +
                      "元,课程每人/课时收费" +
                      htData.coursesChargePerson +
                      "元";
                    // 技术标准
                    this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                      htData.technologyChargePerson;
                    // 课程标准
                    this.ruleForm.fdNewProjectBOList[i].courseStandard =
                      htData.coursesChargePerson;
                    // 判断 - 如果有结算人数;数据参与计算,否则不参与计算
                    if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                      // 平台技术服务费（元）= 技术每人/课时收费 * 结算人数 * 总课时
                      this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].technologyStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0)
                      ).toFixed(2);
                      // 课程服务费（元）= 课程每人/课时收费 * 结算人数 * 总课时
                      this.ruleForm.fdNewProjectBOList[i].courseFee = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee || 0
                        ) +
                        Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                        )
                      ).toFixed(2);
                      // 判断 - 公共课是否收费
                      if (htData.publicCourses == "否") {
                        // 课程服务费（元）= (总课时 - 公共课时) * 课程每人/课时收费 * 结算人数
                        this.ruleForm.fdNewProjectBOList[i].courseFee = (
                          (Number(
                            this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0
                          ) -
                            Number(
                              this.ruleForm.fdNewProjectBOList[i].publicLessonNum || 0
                            )) *
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].courseStandard || 0
                          ) *
                          Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                        ).toFixed(2);
                        // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                        this.ruleForm.fdNewProjectBOList[i].paycount = (
                          Number(
                            this.ruleForm.fdNewProjectBOList[i]
                              .platformTechnicalFee || 0
                          ) +
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                          )
                        ).toFixed(2);
                        // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                        this.ruleForm.fdNewProjectBOList[
                          i
                        ].platformSettlementAmount = (
                          Number(
                            this.ruleForm.fdNewProjectBOList[i]
                              .platformTechnicalFee || 0
                          ) +
                          Number(this.ruleForm.fdNewProjectBOList[i].courseFee || 0) +
                          Number(
                            this.ruleForm.fdNewProjectBOList[i].platformTotalFee || 0
                          )
                        ).toFixed(2);
                      }
                    } else {
                      // 平台技术服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee =
                        "";
                      // 课程服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                  }
                }
                // settleStandardType = 20;技术/课程费用合计
                if (lData.settleStandardType == "20") {
                  this.show = true;
                  this.show1 = false;
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = 0;
                  this.ruleForm.fdNewProjectBOList[i].courseFee = 0;
                  // charge = 10;按比例收费
                  if (htData.charge == "10") {
                    this.disabled1 = false;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    this.percentage = true;
                    this.chargeMessage =
                      "技术/课程费用合计,按比例收费,比例" + htData.ratio + "%";
                    // 收费标准
                    this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                      htData.ratio;
                    // 判断 - 国家补贴标准(元) && 结算人数;数据参与计算,否则不参与计算
                    if (
                      this.ruleForm.fdNewProjectBOList[i]
                        .countrySubsidyStandard &&
                      this.ruleForm.fdNewProjectBOList[i].settleNum
                    ) {
                      // 平台服务费（元）= 按比例收费/100 * 国家补贴标准(元) * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                        ) /
                          100) *
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .countrySubsidyStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 按比例收费/100 * 国家补贴标准(元) * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                        ) /
                          100) *
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .countrySubsidyStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 -平台结算金额（元）= 按比例收费/100 * 国家补贴标准(元) * 结算人数
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        (Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                        ) /
                          100) *
                        Number(
                          this.ruleForm.fdNewProjectBOList[i]
                            .countrySubsidyStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                    } else {
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                  }
                  // charge = 20;按人数收费
                  if (htData.charge == "20") {
                    this.disabled1 = true;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    this.percentage = false;
                    console.log(htData?.chargeUnPerson ?? '');
                    this.chargeMessage =
                      "技术/课程费用合计,领取补贴每人收费" +
                      htData.chargePerson +
                      "元,未领取补贴每人" +
                      (htData?.chargeUnPerson ?? ' ') +
                      "元";
                    // 收费标准
                    this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                      htData.chargePerson;
                    // 判断 - 结算人数;数据参与计算,否则不参与计算
                    if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                      // 平台服务费（元）= 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                        (Number(this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)) +
                        (Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard || 0))
                      ).toFixed(2);
                      //  列表不显示 - 总费用 = 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        (Number(this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)) +
                        (Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard || 0))
                      ).toFixed(2);
                      // 列表不显示 - 平台结算金额（元） = 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        (Number(this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)) +
                        (Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard || 0))
                      ).toFixed(2);
                    } else {
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                    this.$set(this.ruleForm.fdNewProjectBOList[i],'notSubsidiesNum',(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum?this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum:''))
                    this.$set(this.ruleForm.fdNewProjectBOList[i],'notSubsidiesStandard',(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard?this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard:''))
                  }
                  // charge = 30;按课时收费
                  if (htData.charge == "30") {
                    this.disabled1 = true;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    this.chargeMessage =
                      "技术/课程费用合计,按课时每人收费" +
                      htData.chargeHourPerson +
                      "元";
                    // 收费标准
                    this.ruleForm.fdNewProjectBOList[i].chargeStandard = htData.chargeHourPerson;
                    // 判断 - 总课时 && 结算人数;数据参与计算,否则不参与计算
                    if (
                      this.ruleForm.fdNewProjectBOList[i].totalPeriod &&
                      this.ruleForm.fdNewProjectBOList[i].settleNum
                    ) {
                      // 平台服务费（元）= 每人/课时收费 * 总课时 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 每人/课时收费 * 总课时 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                      // 列表不显示 -平台结算金额（元）= 每人/课时收费 * 总课时 * 结算人数
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard || 0
                        ) *
                        Number(this.ruleForm.fdNewProjectBOList[i].totalPeriod || 0) *
                        Number(this.ruleForm.fdNewProjectBOList[i].settleNum || 0)
                      ).toFixed(2);
                    } else {
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                  }
                  // charge = 40;按次数结算
                  if (htData.charge == "40") {
                    this.disabled1 = true;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    let message = "";
                    for (var n = 0; n < htData.SettlementByTimeList.length; n++) {
                      message +=
                        "第" +
                        (n + 1) +
                        "次" +
                        htData.SettlementByTimeList[n].SettlementByTime +
                        "元/人;";
                      if (
                        n == this.ruleForm.fdNewProjectBOList[i].projectSettleNum
                      ) {
                        this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                          htData.SettlementByTimeList[n].SettlementByTime;
                      }
                      // if (
                      //   this.ruleForm.fdNewProjectBOList[i].projectSettleNum >= htData.SettlementByTimeList.length
                      // ) {
                      //   console.log(n)
                      //   // 收费标准
                      //   this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                      //     htData.SettlementByTimeList[0].SettlementByTime;
                      // } else {
                      //    if (
                      //  n +=
                      //   this.ruleForm.fdNewProjectBOList[i].projectSettleNum && n<htData.SettlementByTimeList.length
                      // ) {
                      //   console.log(n)
                      //   // 收费标准
                      //   this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                      //     htData.SettlementByTimeList[n].SettlementByTime ||''
                      // }
                      // }
                    }
                    this.chargeMessage = "按次数结算:" + message;
                    // 收费标准
                    // this.ruleForm.fdNewProjectBOList[i].chargeStandard = htData.chargeHourPerson;
                    // 判断 - 总课时 && 结算人数;数据参与计算,否则不参与计算
                    if (this.ruleForm.fdNewProjectBOList[i].settleNum) {
                      // 平台服务费（元）= 次数收费 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard
                        ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                      ).toFixed(2);
                      // 列表不显示 - 总费用 = 每人/课时收费 * 总课时 * 结算人数
                      this.ruleForm.fdNewProjectBOList[i].paycount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard
                        ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                      ).toFixed(2);
                      // 列表不显示 -平台结算金额（元）= 次数收费 * 结算人数
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = (
                        Number(
                          this.ruleForm.fdNewProjectBOList[i].chargeStandard
                        ) * Number(this.ruleForm.fdNewProjectBOList[i].settleNum)
                      ).toFixed(2);
                    } else {
                      // 平台服务费（元）
                      this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                      // 列表不显示 - 总费用
                      this.ruleForm.fdNewProjectBOList[i].paycount = "";
                      // 列表不显示 -平台结算金额（元）
                      this.ruleForm.fdNewProjectBOList[
                        i
                      ].platformSettlementAmount = "";
                    }
                  }
                }
                // settleStandardType = 30;固定费用
                if (lData.settleStandardType == "30") {
                  this.disabled1 = false;
                  this.disabled2 = false;
                  this.disabled3 = false;
                  // this.show = false;
                  // this.show1 = false;
                  this.chargeMessage = "固定费用" + htData.fixedCost + "元/年";
                  // 平台服务费（元）= 固定费用
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee =
                    htData.fixedCost;
                  // 列表不显示 - 总费用 = 固定费用
                  this.ruleForm.fdNewProjectBOList[i].paycount = htData.fixedCost;
                  // 列表不显示 - 平台结算金额（元）= 固定费用
                  this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                    htData.fixedCost;
                }
                // settleStandardType = 40;其他
                if (lData.settleStandardType == "40") {
                  this.chargeMessage = "其他";
                }
                this.isAdvanceChargeHandle(lData.collectionTimeAgreement, this.ruleForm.fdNewProjectBOList[i])
              } else {
                // 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                this.$message({
                  message:
                    "该“" +
                    lData.contractName +
                    "”的合同结算标准有误，请联系管理员!",
                  type: "warning",
                });
                break;
              }
            } else {
              if (Object.values(htData).length > 0) {
                // settleStandardType = 10;技术/课程费用分开
                if (lData.settleStandardType == "10") {
                  this.show = false;
                  this.show1 = true;
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = 0;
                  if (
                    !this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount
                  ) {
                    this.$set(
                      this.ruleForm.fdNewProjectBOList[i],
                      "platformSettlementAmount",
                      ""
                    );
                  }
                  // charge = 10;按比例收费
                  if (htData.charge == "10") {
                    this.disabled1 = false;
                    this.disabled2 = false;
                    this.disabled3 = true;
                    this.percentage1 = true;
                    this.chargeMessage =
                      "技术/课程费用分开,按比例收费,技术比例" +
                      htData.technologyRatio +
                      "%,课程比例" +
                      htData.coursesRatio +
                      "%";
                    // 技术标准
                    // this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                    //     htData.technologyRatio;
                    // 课程标准
                    // this.ruleForm.fdNewProjectBOList[i].courseStandard =
                    //     htData.coursesRatio;
                  }
                  // charge = 20;按人数收费
                  if (htData.charge == "20") {
                    this.disabled1 = true;
                    this.disabled2 = false;
                    this.disabled3 = true;
                    this.percentage1 = false;
                    this.chargeMessage =
                      "技术/课程费用分开,按人数收费,技术每人收费" +
                      htData.technologyCharge +
                      "元,课程每人收" +
                      htData.coursesCharge +
                      "元";
                    // 技术标准
                    // this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                    //     htData.technologyCharge;
                    // 课程标准
                    // this.ruleForm.fdNewProjectBOList[i].courseStandard =
                    //     htData.coursesCharge;
                  }
                  // charge = 30;按课时收费
                  if (htData.charge == "30") {
                    this.disabled1 = true;
                    this.disabled2 = false;
                    this.disabled3 = true;
                    this.percentage1 = false;
                    this.chargeMessage =
                      "技术/课程费用分开,按课时收费,技术每人/课时收费" +
                      htData.technologyChargePerson +
                      "元,课程每人/课时收费" +
                      htData.coursesChargePerson +
                      "元";
                    // 技术标准
                    // this.ruleForm.fdNewProjectBOList[i].technologyStandard =
                    //     htData.technologyChargePerson;
                    // 课程标准
                    // this.ruleForm.fdNewProjectBOList[i].courseStandard =
                    //     htData.coursesChargePerson;
                  }
                }
                // settleStandardType = 20;技术/课程费用合计
                if (lData.settleStandardType == "20") {
                  this.show = true;
                  this.show1 = false;
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = 0;
                  this.ruleForm.fdNewProjectBOList[i].courseFee = 0;
                  // charge = 10;按比例收费
                  if (htData.charge == "10") {
                    this.chargeMessage =
                      "技术/课程费用合计,按比例收费,比例" + htData.ratio + "%";
                    // 收费标准
                    // this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    //   htData.ratio;
                    this.disabled1 = false;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    this.percentage = true;
                  }
                  // charge = 20;按人数收费
                  if (htData.charge == "20") {
                    this.chargeMessage =
                      "技术/课程费用合计,领取补贴每人收费" +
                      htData.chargePerson +
                      "元,未领取补贴每人" +
                      (htData?.chargeUnPerson ?? ' ') +
                      "元";
                    // 收费标准
                    // this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    //   htData.chargePerson;
                    this.disabled1 = true;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    this.percentage = false;
                    this.$set(this.ruleForm.fdNewProjectBOList[i],'notSubsidiesNum',(this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum?this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum:''))
                    this.$set(this.ruleForm.fdNewProjectBOList[i],'notSubsidiesStandard',(this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard?this.ruleForm.fdNewProjectBOList[i].notSubsidiesStandard:''))
                  }
                  // charge = 30;按课时收费
                  if (htData.charge == "30") {
                    this.chargeMessage =
                      "技术/课程费用合计,按课时每人收费" +
                      htData.chargeHourPerson +
                      "元";
                    // 收费标准
                    // this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                    //   htData.chargeHourPerson;
                    this.disabled1 = true;
                    this.disabled2 = true;
                    this.disabled3 = false;
                  }
                  // charge = 40;按次数结算
                  if (htData.charge == "40") {
                    // this.$set(
                    //     this.ruleForm.fdNewProjectBOList[i],
                    //     "chargeStandard",
                    //     ""
                    // );
                    this.disabled1 = true;
                    this.disabled2 = true;
                    this.disabled3 = false;
                    let message = "";
                    for (var n = 0; n < htData.SettlementByTimeList.length; n++) {
                      message +=
                        "第" +
                        (n + 1) +
                        "次" +
                        htData.SettlementByTimeList[n].SettlementByTime +
                        "元/人;";
                      // if (this.ruleForm.fdNewProjectBOList[i].chargeStandard) {
                      //   this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                      //       this.ruleForm.fdNewProjectBOList[i].chargeStandard ||
                      //       "";
                      // } else {
                      //   if (
                      //       n ==
                      //       this.ruleForm.fdNewProjectBOList[i].projectSettleNum
                      //   ) {
                      //     this.ruleForm.fdNewProjectBOList[i].chargeStandard =
                      //         htData.SettlementByTimeList[n].SettlementByTime;
                      //   }
                      // }
                    }
                    this.chargeMessage = "按次数结算:" + message;
                  }
                }
                // settleStandardType = 30;固定费用
                if (lData.settleStandardType == "30") {
                  // this.disabled1 = true;
                  // this.disabled2 = true;
                  // this.disabled3 = false;
                  // this.show = false;
                  // this.show1 = false;
                  this.chargeMessage = "固定费用" + htData.fixedCost + "元/年";
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee =
                    this.ruleForm.fdNewProjectBOList[i].platformTotalFee ||
                    htData.fixedCost;
                  this.ruleForm.fdNewProjectBOList[i].paycount =
                    this.ruleForm.fdNewProjectBOList[i].paycount ||
                    htData.fixedCost;
                  this.ruleForm.fdNewProjectBOList[i].platformSettlementAmount =
                    this.ruleForm.fdNewProjectBOList[i]
                      .platformSettlementAmount || htData.fixedCost;
                }
                // settleStandardType = 40;其他
                if (lData.settleStandardType == "40") {
                  this.chargeMessage = "其他";
                }
              } else {
                // 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                this.$message({
                  message:
                    "该“" +
                    lData.contractName +
                    "”的合同结算标准有误，请联系管理员!",
                  type: "warning",
                });
                break;
              }
            }
            // console.log(this.settlementMethod,this.ruleForm.fdNewProjectBOList[i])

            // console.log(1111111,this.ruleForm.fdNewProjectBOList[i].settleNum)
            if (this.fatherData.auditType == '05' && this.fatherData.billApplySource == '2') {
              this.algorithm(i, 'list')
            }
          }
          this.isFirstFlag = false
        }
        this.tableKey = Math.random()
      },
    },
  },
  methods: {
    //查看附件详情
    checkTheAttachment () {
      this.previewLoding = true;
    },
    //查看附件列表
    lookPdf (item) {
      this.fileType = item.fileType;
      if (item.fileType == "pdf" || item.fileType == "PDF") {
        this.previewLoding1 = true;
        setTimeout(() => {
          pdf.embed(item.fileUrl, "#pdf-cert3");
        }, 300);
      } else {
        this.$refs['img' + item.billFileId].showViewer = true;
      }
    },
    // 结算方式change
    settlementMethodChange () {
      for (
        let i = 0;
        i < this.ruleForm.fdNewProjectBOList.length;
        i++
      ) {
        this.settleNumSource(this.ruleForm.fdNewProjectBOList[i], i, true)
      }
    },
    // 获取 -结算方式字典
    getcontractSettlementMethod_type () {
      // 类型
      let arr1 = this.$setDictionary("CONTRACT_SETTLEMENT_METHOD", "list");
      for (const key in arr1) {
        this.settlementMethodList.push({
          label: arr1[key],
          value: key
        });
      }
    },
    // 获取 - 获取字典
    getInvoice_type () {
      // 类型
      let arr1 = this.$setDictionary("BILL_NO_MARK", "list");
      for (const key in arr1) {
        this.billNoMarkData.push({
          label: arr1[key],
          value: key
        });
      }
    },
    // 面包屑 - 返回上个页面
    goReturn () {
      if (this.fatherData.addEdit == "newSettlementSheet") {
        this.$router.push({
          path: "/web/financialSettlement/settlement/singleAdministration",
          query: {
            active: this.fatherData.type,
            refresh: this.fatherData.refresh,
            multipleTable: "edit",
          },
        });
      } else {
        this.$router.push({
          path: this.fatherData.addEdit == "edit"
            ? "/web/financialSettlement/settlement/singleAdministration"
            : "/web/financialSettlement/settlement/toBeSettledList",
          query: {
            active: this.fatherData.type,
            refresh: this.fatherData.refresh,
            multipleTable: this.fatherData.addEdit == "edit" ? "" : "multipleTable",
          },
        });
      }
    },
    // 预览合同
    previewContract () {
      if (this.ruleForm.contractId) {
        this.dialogVisible3 = true;
      } else {
        this.$message.error("请选择合同");
      }
    },
    // 预览 - 查看班级明细
    seeClassEetails () {
      // let projects = [];
      // for (let i = 0; i < this.ruleForm.fdNewProjectBOList.length; i++) {
      //   projects.push({
      //     projectId: this.ruleForm.fdNewProjectBOList[i].projectId,
      //     settleNum: this.ruleForm.fdNewProjectBOList[i].settleNum,
      //   });
      // }
      this.$post("/biz/new/bill/selectProjectDetail", {
        contractId: this.ruleForm.contractId,
        fdNewProjectBOList: this.ruleForm.fdNewProjectBOList,
      })
        .then((res) => {
          this.dialogVisible1 = true;
          this.$nextTick(() => {
            pdf.embed(res.message, "#pdf-cert1");
          });
        })
        .catch(() => {
          return;
        });
    },
    // 获取结算单数据
    generateSettlementDocument () {
      // 添加结算单
      if (this.fatherData.addEdit == "add") {
        this.$post("/biz/new/bill/generateStatement", {
          projectIds: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.trainTypeId = res.data.trainTypeId; // 培训类型
              this.ruleForm.fdNewProjectBOList = res.data.eduProjectList; // 国家补贴标准数据

              this.settlementMethod = res.data.fdNewContractList[0].settlementMethod
              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                // console.log(this.settlementMethod,this.ruleForm.fdNewProjectBOList[i])
                this.settleNumSource(this.ruleForm.fdNewProjectBOList[i], i)
                if (
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].totalPeriod == 0) {
                  this.ruleForm.fdNewProjectBOList[i].totalPeriod = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].publicLessonNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].publicLessonNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].platformTotalFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                }
                this.$set(
                  this.ruleForm.fdNewProjectBOList[i],
                  "chargeStandard",
                  ""
                );
                this.$set(
                  this.ruleForm.fdNewProjectBOList[i],
                  "courseStandard",
                  ""
                );
                this.$set(
                  this.ruleForm.fdNewProjectBOList[i],
                  "technologyStandard",
                  ""
                );
                this.$set(this.ruleForm.fdNewProjectBOList[i], "paycount", "");
                this.$set(
                  this.ruleForm.fdNewProjectBOList[i],
                  "platformSettlementAmount",
                  ""
                );
              }
              if (res.data.fdNewContractList.length) {
                this.fdNewContractLists = res.data.fdNewContractList; // 合同数据
                this.ruleForm.contractId = res.data.fdNewContractList[0].contractId; // 默认赋值合同
                let obj = {};
                obj = this.fdNewContractLists.find((item) => {
                  return (
                    item.contractId == res.data.fdNewContractList[0].contractId
                  ); //筛选出对应数据
                });
                this.ruleForm.isApproval = obj.isApproval;
                this.paymentMethod = res.data.paymentMethod //付费形式

              }
            }
          })
          .catch(() => {
            return;
          });
      }
      // 编辑结算单
      if (this.fatherData.addEdit == "edit") {
        this.$post("/biz/new/bill/getInfo", {
          billId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.chargesSettlement = res.data.fdNewBill.chargesSettlement;
              this.ruleForm.fdNewProjectBOList = res.data.fdNewProjectBOList; // 国家补贴标准数据
              this.attachmentList = res.data.fileList
              // console.log('123123123',res.data.fdNewProjectBOList.find(item => item.contractId == res.data.fdNewBill.contractId))
              this.settlementMethod = res.data.fdNewBill.settlementMethod || res.data.fdNewContractList.find(item => item.contractId == res.data.fdNewBill.contractId)?.settlementMethod || ''

              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                if (
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                // ltf
                if (this.ruleForm.fdNewProjectBOList[i].subsidiesNum == 0) {
                    this.ruleForm.fdNewProjectBOList[i].subsidiesNum = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum == 0) {
                    this.ruleForm.fdNewProjectBOList[i].notSubsidiesNum = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].totalPeriod == 0) {
                  this.ruleForm.fdNewProjectBOList[i].totalPeriod = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].publicLessonNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].publicLessonNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].platformTotalFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].paycount == 0) {
                  this.ruleForm.fdNewProjectBOList[i].paycount = "";
                }
                // this.$set(
                //     this.ruleForm.fdNewProjectBOList[i],
                //     "chargeStandard",
                //     ""
                // );
                // this.$set(
                //     this.ruleForm.fdNewProjectBOList[i],
                //     "courseStandard",
                //     ""
                // );
                // this.$set(
                //     this.ruleForm.fdNewProjectBOList[i],
                //     "technologyStandard",
                //     ""
                // );
                // this.$set(this.ruleForm.fdNewProjectBOList[i], "paycount", "");
              }
              if (res.data.fdNewContractList.length) {
                this.fdNewContractLists = res.data.fdNewContractList; // 合同数据
                this.ruleForm.contractId = res.data.fdNewBill.contractId || ""; // 默认赋值合同
                // 更改合同里的结算标准 - 只是更改当前页面的，实际合同里的数据不会改变
                for (let i = 0; i < res.data.fdNewContractList.length; i++) {
                  if (res.data.fdNewContractList[i].contractId == res.data.fdNewBill.contractId) {
                    res.data.fdNewContractList[i].chargesSettlement = res.data.fdNewBill.chargesSettlement
                  }
                }
              }
              this.ruleForm.trainTypeId = res.data.fdNewBill.trainTypeId; // 培训类型
              this.ruleForm.settlementUserCount = res.data.fdNewBill.settlementUserCount; // 潜在字段 - 结算人数
              this.ruleForm.totalLessonNum = res.data.fdNewBill.totalLessonNum; // 潜在字段 - 总课时
              this.ruleForm.openLessonNum = res.data.fdNewBill.openLessonNum; // 潜在字段 - 总公共课时
              this.ruleForm.payerAmount = res.data.fdNewBill.payerAmount; // 潜在字段 - 总费用（元）
              this.ruleForm.settlementAmount = res.data.fdNewBill.settlementAmount; // 潜在字段 - 平台服务费（元）
              this.ruleForm.remark = res.data.fdNewBill.remark, // 备注
                this.ruleForm.payerName = res.data.fdNewBill.payerName, // 付款单位
                this.ruleForm.replacePayerName = res.data.fdNewBill.replacePayerName, // 代付款单位
                this.ruleForm.payeeName = res.data.fdNewBill.payeeName, // 收款单位
                this.ruleForm.payeeBank = res.data.fdNewBill.payeeBank, // 开户银行
                this.ruleForm.payeeAccount = res.data.fdNewBill.payeeAccount, // 银行账号
                this.ruleForm.billId = res.data.fdNewBill.billId; // 结算单id
              this.ruleForm.billNo = res.data.fdNewBill.billNo; // 结算单编号
              this.ruleForm.billNoMark = res.data.fdNewBill.billNoMark || ""; // 类型
              this.paymentMethod = res.data.paymentMethod; //付费形式
            }
          })
          .catch(() => {
            return;
          });
      }
      // 新结算单
      if (this.fatherData.addEdit == "newSettlementSheet") {
        this.$post("/biz/new/bill/generateNewBill", {
          billId: this.fatherData.id,
        })
          .then((res) => {
            if (res.status == 0) {
              this.ruleForm.fdNewProjectBOList =
                res.data.fdNewProjectBONoSettleList; // 国家补贴标准数据
              for (
                let i = 0;
                i < this.ruleForm.fdNewProjectBOList.length;
                i++
              ) {
                if (
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard ==
                  0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].countrySubsidyStandard =
                    "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].settleNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].settleNum = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].totalPeriod == 0) {
                  this.ruleForm.fdNewProjectBOList[i].totalPeriod = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].publicLessonNum == 0) {
                  this.ruleForm.fdNewProjectBOList[i].publicLessonNum = "";
                }
                if (
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee == 0
                ) {
                  this.ruleForm.fdNewProjectBOList[i].platformTechnicalFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].courseFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].courseFee = "";
                }
                if (this.ruleForm.fdNewProjectBOList[i].platformTotalFee == 0) {
                  this.ruleForm.fdNewProjectBOList[i].platformTotalFee = "";
                }
                this.$set(
                  this.ruleForm.fdNewProjectBOList[i],
                  "secondPerson",
                  ""
                );
                this.$set(this.ruleForm.fdNewProjectBOList[i], "secondFee", "");
              }
              if (res.data.fdNewContractList.length) {
                this.fdNewContractLists = res.data.fdNewContractList; // 合同数据
                this.ruleForm.contractId = res.data.fdNewBill.contractId || ""; // 默认赋值合同
                // 更改合同里的结算标准 - 只是更改当前页面的，实际合同里的数据不会改变
                for (let i = 0; i < res.data.fdNewContractList.length; i++) {
                  if (res.data.fdNewContractList[i].contractId == res.data.fdNewBill.contractId) {
                    res.data.fdNewContractList[i].chargesSettlement = res.data.fdNewBill.chargesSettlement
                  }
                }
              }
              this.ruleForm.trainTypeId = res.data.fdNewBill.trainTypeId; // 培训类型
              this.ruleForm.settlementUserCount = res.data.fdNewBill.settlementUserCount; // 潜在字段 - 结算人数
              this.ruleForm.totalLessonNum = res.data.fdNewBill.totalLessonNum; // 潜在字段 - 总课时
              this.ruleForm.openLessonNum = res.data.fdNewBill.openLessonNum; // 潜在字段 - 总公共课时
              this.ruleForm.payerAmount = res.data.fdNewBill.payerAmount; // 潜在字段 - 总费用（元）
              this.ruleForm.settlementAmount = res.data.fdNewBill.settlementAmount || 0; // 潜在字段 - 平台服务费（元）
              this.ruleForm.remark = res.data.fdNewBill.remark, // 备注
                this.ruleForm.payerName = res.data.fdNewBill.payerName, // 付款单位
                this.ruleForm.replacePayerName = res.data.fdNewBill.replacePayerName, // 代付款单位
                this.ruleForm.payeeName = res.data.fdNewBill.payeeName, // 收款单位
                this.ruleForm.payeeBank = res.data.fdNewBill.payeeBank, // 开户银行
                this.ruleForm.payeeAccount = res.data.fdNewBill.payeeAccount, // 银行账号
                this.ruleForm.billId = res.data.fdNewBill.billId; // 结算单id
              this.ruleForm.billNo = res.data.fdNewBill.billNo; // 结算单编号
              this.ruleForm.billNoMark = res.data.fdNewBill.billNoMark || ""; // 类型
              this.$post("/biz/new/bill/getNextBillNoBySourceBillNo", {
                billNo: res.data.fdNewBill.billNo,
              })
                .then((res1) => {
                  this.ruleForm.billNo = res1.data; // 结算单编号
                })
                .catch(() => {
                  return;
                });
            }
          })
          .catch(() => {
            return;
          });
      }
    },
    // 更新值
    updateValue(index,type,val){
        console.log(val)
        if(type == 1){
            // 领取补贴人数
            this.ruleForm.fdNewProjectBOList[index].subsidiesNum = val
        }else if(type == 2){
            // 未领取补贴人数
            this.ruleForm.fdNewProjectBOList[index].notSubsidiesNum = val
        }
    },
    // 算法('列表当前行的下标','列表当前行的数据');国家补贴标准/结算人数/总课时/公共课 =>计算:平台技术服务费（元）/课程服务费（元）/平台服务费（元）
    algorithm (index, type) {
      if (type == "list") {
        // 赋值该选中合同参数
        let lData = [];
        // 循环合同,判断当前合同;取该合同里的参数
        for (let i = 0; i < this.fdNewContractLists.length; i++) {
          if (
            this.fdNewContractLists[i].contractId == this.ruleForm.contractId
          ) {
            lData = this.fdNewContractLists[i];
          }
        }
        // 解出该合同中(结算信息)中定义的参数;用来参与计算
        let htData = JSON.parse(lData.settleContent);
        // settleStandardType = 10;技术/课程费用分开
        if (lData.settleStandardType == "10") {
          // charge = 10;按比例收费
          if (htData.charge == "10") {
            // 判断 - 国家补贴标准(元) && 结算人数
            if (
              this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard &&
              this.ruleForm.fdNewProjectBOList[index].settleNum
            ) {
              // 平台技术服务费（元）= 技术标准 * 国家补贴标准 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = (
                (Number(
                  this.ruleForm.fdNewProjectBOList[index].technologyStandard || 0
                ) /
                  100) *
                Number(
                  this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 课程服务费（元）= 课程标准 * 国家补贴标准 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].courseFee = (
                (Number(
                  this.ruleForm.fdNewProjectBOList[index].courseStandard || 0
                ) /
                  100) *
                Number(
                  this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                ) +
                Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0)
              ).toFixed(2);
              // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
              // 判断 - 公共课是否收费
              if (htData.publicCourses == "否") {
                // 课程服务费（元）= 课程服务费（元）- (公共课时/总课时) * 课程服务费（元）
                this.ruleForm.fdNewProjectBOList[index].courseFee = (
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee) -
                  (Number(
                    this.ruleForm.fdNewProjectBOList[index].publicLessonNum
                  ) /
                    Number(
                      this.ruleForm.fdNewProjectBOList[index].totalPeriod
                    )) *
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee)
                ).toFixed(2);
                // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[index].paycount = (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee
                  )
                ).toFixed(2);
                // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[
                  index
                ].platformSettlementAmount = (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee
                  )
                ).toFixed(2);
              }
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
          // charge = 20;按人数收费
          if (htData.charge == "20") {
            // 判断 - 如果有结算人数;数据参与计算,否则不参与计算
            if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
              // 平台技术服务费（元）= 技术每人收费 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].technologyStandard || 0
                ) * Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 课程服务费（元） = 课程每人收费 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].courseFee = (
                Number(this.ruleForm.fdNewProjectBOList[index].courseStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                ) +
                Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0)
              ).toFixed(2);
              // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
              // 判断 - 公共课是否收费
              if (htData.publicCourses == "否") {
                // 课程服务费（元）= 课程服务费（元）- (公共课时 / 总课时) * 课程服务费
                this.ruleForm.fdNewProjectBOList[index].courseFee = (
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) -
                  (Number(
                    this.ruleForm.fdNewProjectBOList[index].publicLessonNum || 0
                  ) /
                    Number(
                      this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0
                    )) *
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0)
                ).toFixed(2);
                // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[index].paycount = (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
                // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[
                  index
                ].platformSettlementAmount = (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
              }
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
          // charge = 30;按课时收费
          if (htData.charge == "30") {
            // 判断 - 如果有结算人数;数据参与计算,否则不参与计算
            if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
              // 平台技术服务费（元）= 技术每人/课时收费 * 结算人数 * 总课时
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].technologyStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0)
              ).toFixed(2);
              // 课程服务费（元）= 课程每人/课时收费 * 结算人数 * 总课时
              this.ruleForm.fdNewProjectBOList[index].courseFee = (
                Number(this.ruleForm.fdNewProjectBOList[index].courseStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0)
              ).toFixed(2);
              // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                ) +
                Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0)
              ).toFixed(2);
              // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
              // 判断 - 公共课是否收费
              if (htData.publicCourses == "否") {
                // 课程服务费（元）= (总课时 - 公共课时) * 课程每人/课时收费 * 结算人数
                this.ruleForm.fdNewProjectBOList[index].courseFee = (
                  (Number(this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0) -
                    Number(
                      this.ruleForm.fdNewProjectBOList[index].publicLessonNum || 0
                    )) *
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].courseStandard || 0
                  ) *
                  Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
                ).toFixed(2);
                // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[index].paycount = (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
                // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
                this.ruleForm.fdNewProjectBOList[
                  index
                ].platformSettlementAmount = (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
                  ) +
                  Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0
                  )
                ).toFixed(2);
              }
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
        }
        // settleStandardType = 20;技术/课程费用合计
        if (lData.settleStandardType == "20") {
          // charge = 10;按比例收费
          if (htData.charge == "10") {
            // 判断 - 国家补贴标准(元) && 结算人数;数据参与计算,否则不参与计算
            if (
              this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard &&
              this.ruleForm.fdNewProjectBOList[index].settleNum
            ) {
              // 平台服务费（元）= 按比例收费/100 * 国家补贴标准(元) * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = (
                (Number(
                  this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0
                ) /
                  100) *
                Number(
                  this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 总费用 = 按比例收费/100 * 国家补贴标准(元) * 结算人数
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                (Number(
                  this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0
                ) /
                  100) *
                Number(
                  this.ruleForm.fdNewProjectBOList[index].countrySubsidyStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 -平台结算金额（元）= 按比例收费/100 * 国家补贴标准(元) * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                (
                  (Number(
                    this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0
                  ) /
                    100) *
                  Number(
                    this.ruleForm.fdNewProjectBOList[index]
                      .countrySubsidyStandard || 0
                  ) *
                  Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
                ).toFixed(2);
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
          // charge = 20;按人数收费
          if (htData.charge == "20") {
            // 判断 - 结算人数;数据参与计算,否则不参与计算
            if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
              // 平台服务费（元）= 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = (
                (Number(this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)) +
                (Number(this.ruleForm.fdNewProjectBOList[index].notSubsidiesNum || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].notSubsidiesStandard || 0))
              ).toFixed(2);
              // 列表不显示 - 总费用 = 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                (Number(this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)) +
                (Number(this.ruleForm.fdNewProjectBOList[index].notSubsidiesNum || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].notSubsidiesStandard || 0))
              ).toFixed(2);
              // 列表不显示 - 平台结算金额（元）= 领取补贴收费标准*领取补贴人数＋未领取补贴标准*未领取补贴人数
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =(
                (Number(this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)) +
                (Number(this.ruleForm.fdNewProjectBOList[index].notSubsidiesNum || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].notSubsidiesStandard || 0))
                ).toFixed(2);
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
          // charge = 30;按课时收费
          if (htData.charge == "30") {
            // 判断 - 总课时 && 结算人数;数据参与计算,否则不参与计算
            if (
              this.ruleForm.fdNewProjectBOList[index].totalPeriod &&
              this.ruleForm.fdNewProjectBOList[index].settleNum
            ) {
              // 平台服务费（元）= 每人/课时收费 * 总课时 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = (
                Number(this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 总费用 = 每人/课时收费 * 总课时 * 结算人数
              //  this.ruleForm.fdNewProjectBOList[index].paycount =  Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee).toFixed(2);
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                Number(this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 平台结算金额（元）= 每人收费 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].chargeStandard
                  ) *
                  Number(this.ruleForm.fdNewProjectBOList[index].totalPeriod || 0) *
                  Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
                ).toFixed(2);
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
          // charge = 40;按次数结算
          if (htData.charge == "40") {
            // 判断 - 总课时 && 结算人数;数据参与计算,否则不参与计算
            if (this.ruleForm.fdNewProjectBOList[index].settleNum) {
              // 平台服务费（元）= 每人/课时收费 * 总课时 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 总费用 = 每人/课时收费 * 总课时 * 结算人数
              //  this.ruleForm.fdNewProjectBOList[index].paycount =  Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee).toFixed(2);
              this.ruleForm.fdNewProjectBOList[index].paycount = (
                Number(
                  this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0
                ) *
                Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
              ).toFixed(2);
              // 列表不显示 - 平台结算金额（元）= 每人收费 * 结算人数
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                (
                  Number(
                    this.ruleForm.fdNewProjectBOList[index].chargeStandard || 0
                  ) *
                  Number(this.ruleForm.fdNewProjectBOList[index].settleNum || 0)
                ).toFixed(2);
            } else {
              // 平台技术服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee = "";
              // 课程服务费（元）
              this.ruleForm.fdNewProjectBOList[index].courseFee = "";
              // 平台服务费（元）
              this.ruleForm.fdNewProjectBOList[index].platformTotalFee = "";
              // 列表不显示 - 总费用
              this.ruleForm.fdNewProjectBOList[index].paycount = "";
              // 列表不显示 -平台结算金额（元）
              this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
                "";
            }
          }
        }
        // settleStandardType = 30;固定费用
        if (lData.settleStandardType == "30") {
          // 平台服务费（元）= 固定费用
          this.ruleForm.fdNewProjectBOList[index].platformTotalFee =
            htData.fixedCost;
          // 列表不显示 - 总费用 = 固定费用
          this.ruleForm.fdNewProjectBOList[index].paycount = htData.fixedCost;
          // 列表不显示 - 平台结算金额（元）= 固定费用
          this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount =
            htData.fixedCost;
        }
        // settleStandardType = 40;其他
        if (lData.settleStandardType == "40") {
          // 列表不显示 - 总费用 = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
          this.ruleForm.fdNewProjectBOList[index].paycount = (
            Number(
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
            ) +
            Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
            Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0)
          ).toFixed(2);
          // 列表不显示 - 平台结算金额（元）= 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
          this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
            Number(
              this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee || 0
            ) +
            Number(this.ruleForm.fdNewProjectBOList[index].courseFee || 0) +
            Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee || 0)
          ).toFixed(2);
        }
      }
    },
    // 算法('列表当前行的下标','列表当前行的数据');总费用 和 平台结算金额（元） = 平台技术服务费（元）+ 课程服务费（元）+ 平台服务费（元）
    platformCoursesCost (index, type) {
      if (type == "list") {
        // let lData = [];
        // for (let i = 0; i < this.fdNewContractLists.length; i++) {
        //   if (
        //     this.fdNewContractLists[i].contractId == this.ruleForm.contractId
        //   ) {
        //     lData = this.fdNewContractLists[i];
        //   }
        // }
        // this.ruleForm.fdNewProjectBOList[index].paycount =
        //   Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
        // .toFixed(2);
        // if (lData.settleStandardType == "40") {
        //  this.ruleForm.fdNewProjectBOList[index].paycount = (
        //   Number(
        //     this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
        //   ) +
        //   Number(this.ruleForm.fdNewProjectBOList[index].courseFee) +
        //   Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
        // ).toFixed(2);
        // this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
        //   Number(
        //     this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
        //   ) +
        //   Number(this.ruleForm.fdNewProjectBOList[index].courseFee) +
        //   Number(this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
        // ).toFixed(2);
        // }
        this.ruleForm.fdNewProjectBOList[index].paycount = (
          Number(
            isNaN(this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee)
              ? 0
              : this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
          ) +
          Number(
            isNaN(this.ruleForm.fdNewProjectBOList[index].courseFee)
              ? 0
              : this.ruleForm.fdNewProjectBOList[index].courseFee
          ) +
          Number(
            isNaN(this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
              ? 0
              : this.ruleForm.fdNewProjectBOList[index].platformTotalFee
          )
        ).toFixed(2);
        this.ruleForm.fdNewProjectBOList[index].platformSettlementAmount = (
          Number(
            isNaN(this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee)
              ? 0
              : this.ruleForm.fdNewProjectBOList[index].platformTechnicalFee
          ) +
          Number(
            isNaN(this.ruleForm.fdNewProjectBOList[index].courseFee)
              ? 0
              : this.ruleForm.fdNewProjectBOList[index].courseFee
          ) +
          Number(
            isNaN(this.ruleForm.fdNewProjectBOList[index].platformTotalFee)
              ? 0
              : this.ruleForm.fdNewProjectBOList[index].platformTotalFee
          )
        ).toFixed(2);
      }
    },
    // 生成新结算单 - 删除列
    deleteList (row, index) {
      this.ruleForm.fdNewProjectBOList.splice(index, 1);
    },
    // 生成/预览 - 结算单按钮;1-预览/2-生成 'add'-添加/编辑
    generatePreview (formName, type) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let hData = {
            contractId: this.ruleForm.contractId, // 合同
            billId: this.ruleForm.billId, // 结算单id
            billNo: this.ruleForm.billNo, // 结算单编号
            billNoMark: this.ruleForm.billNoMark, // 类型
            trainTypeId: this.ruleForm.trainTypeId, // 培训类型id
            chargesSettlement: this.ruleForm.chargesSettlement, // 收费标准
            remark: this.ruleForm.remark, // 备注
            payerName: this.ruleForm.payerName, // 付款单位
            replacePayerName: this.ruleForm.replacePayerName, // 付款单位
            payeeName: this.ruleForm.payeeName, // 收款单位
            payeeBank: this.ruleForm.payeeBank, // 开户银行
            payeeAccount: this.ruleForm.payeeAccount, // 银行账号
          };
          let gjbtbzData = [];
          this.ruleForm.fdNewProjectBOList.forEach((item) => {
            gjbtbzData.push(Object.assign({}, item));
          });
          let settlementUserCounts = 0;
          let totalLessonNums = 0;
          let openLessonNums = 0;
          let payerAmounts = 0;
          let settlementAmount = 0;
          let twoPayerAmounts = 0;
          for (let i = 0; i < gjbtbzData.length; i++) {
            // 国家补贴标准
            if (!gjbtbzData[i].countrySubsidyStandard) {
              gjbtbzData[i].countrySubsidyStandard = 0;
            }
            // 课程服务费（元）
            if (!gjbtbzData[i].courseFee) {
              gjbtbzData[i].courseFee = 0;
            }
            // 平台服务费（元）
            if (!gjbtbzData[i].platformTotalFee) {
              gjbtbzData[i].platformTotalFee = 0;
            }
            // 平台技术服务费（元）
            if (!gjbtbzData[i].platformTechnicalFee) {
              gjbtbzData[i].platformTechnicalFee = 0;
            }
            // 结算人数
            if (!gjbtbzData[i].settleNum) {
              gjbtbzData[i].settleNum = 0;
            }
            // ltf
            // 领取补贴人数
            if (!gjbtbzData[i].subsidiesNum) {
                gjbtbzData[i].subsidiesNum = 0;
            }
            // 未领取补贴人数
            if (!gjbtbzData[i].notSubsidiesNum) {
                gjbtbzData[i].notSubsidiesNum = 0;
            }
            // 总课时
            if (!gjbtbzData[i].totalPeriod) {
              gjbtbzData[i].totalPeriod = 0;
            }
            // 公共课时
            if (!gjbtbzData[i].publicLessonNum) {
              gjbtbzData[i].publicLessonNum = 0;
            }
            // 隐藏字段 - 总费用
            if (!gjbtbzData[i].paycount) {
              gjbtbzData[i].paycount = 0;
            }
            // 平台结算金额（元）
            if (!gjbtbzData[i].platformSettlementAmount) {
              gjbtbzData[i].platformSettlementAmount = 0;
            }
            // 平台结算金额（元）- 二次结算金额
            if (this.fatherData.addEdit == "newSettlementSheet") {
              if (!gjbtbzData[i].secondFee) {
                gjbtbzData[i].secondFee = 0;
              }
            }
            settlementUserCounts += Number(gjbtbzData[i].settleNum);
            totalLessonNums += Number(gjbtbzData[i].totalPeriod);
            openLessonNums += Number(gjbtbzData[i].publicLessonNum);
            payerAmounts += Number(gjbtbzData[i].paycount);
            twoPayerAmounts += Number(gjbtbzData[i].secondFee);
            settlementAmount += Number(gjbtbzData[i].platformSettlementAmount);
          }
          hData.fdNewProjectBOList = gjbtbzData; // 整理好的国家补贴标准列表
          hData.settlementUserCount = settlementUserCounts; // 潜在字段 - 结算人数
          hData.totalLessonNum = totalLessonNums; // 潜在字段 - 总课时
          hData.openLessonNum = openLessonNums; // 潜在字段 - 总公共课时
          if (this.fatherData.addEdit == "newSettlementSheet") {
            hData.payerAmount = twoPayerAmounts.toFixed(2); // 潜在字段 - 平台服务费（元）=> 被替换-平台结算金额（元）
          } else {
            hData.payerAmount = payerAmounts.toFixed(2); // 潜在字段 - 平台服务费（元）=>
          }
          hData.payerAmount = payerAmounts.toFixed(2); // 潜在字段 - 平台服务费（元）
          hData.settlementAmount = settlementAmount.toFixed(2); // 潜在字段 - 平台结算金额（元）
          hData.settlementMethod = this.settlementMethod; // 结算方式

          console.log(hData)
          if (type == 1) {
            if (this.fatherData.addEdit == "newSettlementSheet") {
              hData.sourceBillId = this.ruleForm.billId;
              // 二次结算人数
              for (let i = 0; i < gjbtbzData.length; i++) {
                // 二次结算人数
                if (!gjbtbzData[i].secondPerson) {
                  this.$message({
                    message: "请输入二次结算人数",
                    type: "warning",
                  });
                  return;
                }
                // 平台结算金额（元）
                if (!gjbtbzData[i].secondFee) {
                  this.$message({
                    message: "请输入平台结算金额（元）",
                    type: "warning",
                  });
                  return;
                }
              }
            }
            this.$post("/biz/new/bill/insertReview", hData)
              .then((ret) => {
                this.dialogVisible2 = true;
                this.$nextTick(() => {
                  pdf.embed(ret.message, "#pdf-cert2");
                });
              })
              .catch(() => {
                return;
              });
          } else if (type == 2) {
            if (this.fatherData.addEdit == "add") {
              this.$post("/biz/new/bill/insert", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.fatherData.refresh = true;
                    this.goReturn();
                    this.$message({
                      message: "生成成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              // console.log(hData.chargesSettlement);
              this.$post("/biz/new/bill/modify", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.fatherData.refresh = true;
                    this.goReturn();
                    this.$message({
                      message: "编辑成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            }
          } else if (type == 3) {
            hData.discounted = true;
            if (this.fatherData.addEdit == "add") {
              this.$post("/biz/new/bill/insert", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.fatherData.refresh = true;
                    this.goReturn();
                    this.$message({
                      message: "生成成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$post("/biz/new/bill/modifyAndSubmitLeader", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.fatherData.refresh = true;
                    this.goReturn();
                    this.$message({
                      message: "编辑成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            }
          } else if (type == 4) {
            if (gjbtbzData.length > 0) {
              for (let i = 0; i < gjbtbzData.length; i++) {
                // 二次结算人数
                if (!gjbtbzData[i].secondPerson) {
                  this.$message({
                    message: "请输入二次结算人数",
                    type: "warning",
                  });
                  return;
                }
                // 平台结算金额（元）
                if (!gjbtbzData[i].secondFee) {
                  this.$message({
                    message: "请输入平台结算金额（元）",
                    type: "warning",
                  });
                  return;
                }
              }
              hData.sourceBillId = this.ruleForm.billId;
              this.$post("/biz/new/bill/insert", hData)
                .then((ret) => {
                  if (ret.status == 0) {
                    this.fatherData.refresh = true;
                    this.goReturn();
                    this.$message({
                      message: "生成成功",
                      type: "success",
                    });
                  }
                })
                .catch(() => {
                  return;
                });
            } else {
              this.$message({
                message: "无可结算数据",
                type: "warning",
              });
            }
          }
        }
      });
    },
    // 合同切换change事件
    contractChange (val) {
      let obj = {};
      obj = this.fdNewContractLists.find((item) => {
        return item.contractId == val; //筛选出对应数据
      });
      // console.log(obj.settlementMethod)
      this.settlementMethod = obj.settlementMethod
      this.ruleForm.isApproval = obj.isApproval;
    },
    // 驳回
    reject () {
      this.rejectDialog = true;
    },
    // 驳回 - 取消
    toVoidDetermineClose () {
      this.rejectDialog = false;
      this.remark = "";
      this.NameList = [];
    },
    /**
     * @description: 结算人数根据不同的结算方式 取值
     * @param {*} item item
     * @param {*} index index
     * @param {*} isCompute 是否计算
     * @return {*} 无
     */
    settleNumSource (item, index, isCompute = false) {
      if (this.settlementMethod == '10') {//按注册人数
        item.settleNum = item.projectTotalNum
      } else if (this.settlementMethod == '20') {//按入班人数
        item.settleNum = item.projectCurrentNum
      } else if (this.settlementMethod == '30') {//按学习人数
        item.settleNum = item.studyNum
      } else if (this.settlementMethod == '40') {//按完成人数
        item.settleNum = item.completeNum
      } else if (this.settlementMethod == '50') {//按结业人数
        item.settleNum = item.graduateNum
      } else if (this.settlementMethod == '60') {//按补贴人数
        item.settleNum = item.graduateNum
      } else if (!this.settlementMethod) {
        item.settleNum = item.settleNum
      }
      if (isCompute) {
        this.algorithm(index, 'list')
      }
    },
    /**
     * @description: 班级列表中插入是否预付款
     * @param {*} item item
     * @param {*} collectionTimeAgreement 收款时间约定
     * @return {*} 无
     */
    isAdvanceChargeHandle (collectionTimeAgreement, item) {
      if (collectionTimeAgreement != '30') {
        this.$set(item, 'isAdvanceCharge', true)
      } else {
        this.$set(item, 'isAdvanceCharge', false)
      }
    },
    // 驳回 - 确定
    toVoidDetermine () {
      let parmar = {
        billId: this.fatherData.id,
        isPass: false,
        remark: this.remark,
      };
      if (this.remark == "") {
        this.$message({
          message: "请输入作废原因",
          type: "error",
        });
        return;
      }
      this.$post("/biz/new/bill/billVerify", parmar)
        .then((ret) => {
          if (ret.status == "0") {
            this.$message({
              message: "驳回成功",
              type: "success",
            });
            this.rejectDialog = false;
            this.remark == "";
            this.goReturn();
          }
        })
        .catch((err) => {
          return;
        });
    },
  },
};
</script>
<style lang="less">
.demo-ruleForm1 {
  position: relative;
  .ckbjmx {
    position: absolute;
    left: 900px;
  }
  .el-form-item {
    width: 520px;
  }
  .ts {
    .el-form-item__label {
      font-size: 17px;
      font-weight: 600;
    }
  }
  .btbzWitch {
    width: 100%;
    .el-table__header-wrapper {
      th {
        padding: 0;
        background: #5c6be8;
        color: #fff;
        border-color: #5c6be8;
      }
    }
  }
  .tsWidth {
    width: 100%;
    .el-input {
      width: 280px;
      float: left;
    }
  }
  .sfbzWidth {
    min-width: 1100px;
  }
  .operation {
    width: 800px;
    .el-button {
      margin: 0 15px;
    }
  }
  .tb-edit .el-input {
    display: none;
  }

  .tb-edit .current-row .el-input {
    display: block;
  }

  .tb-edit .current-row .el-input + span {
    display: none;
  }
  .tb-edit .current-row .el-select + span {
    display: none;
  }
}
.demo-ruleForm1 /deep/ .el-textarea .el-textarea__inner {
  height: 100%;
}
.demo-ruleForm1 /deep/ .el-textarea .el-input__count {
  line-height: 15px !important;
}
.el-textarea {
  .el-textarea__inner {
    min-height: 16rem !important;
    resize: none;
  }
}
.preview-857 .el-table__header-wrapper {
  th {
    padding: 0;
    background: #5c6be8;
    color: #fff;
    border-color: #5c6be8;
  }
}
</style>